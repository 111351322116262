<template>
    <v-container fluid class="pa-0 py-10 contact-us-page d-flex flex-column align-center justify-center" style="width: 80vw">
        <v-row class="d-flex align-center justify-center">
            <v-col cols="8" class="pa-0 p-relative d-flex flex-column align-center justify-center">
                <div class="text-center">
                    <h1>Need Some Help?</h1>
                    <p>
                        Get in touch today and discuss<br />
                        your vision!
                    </p>
                </div>
                <div class="body-size text-center font-weight-medium my-5">I want to discuss</div>
            </v-col>
        </v-row>

        <div class="mx-auto py-10" :class="[$vuetify.breakpoint.mdAndUp ? 'width-40vw' : '']">
            <v-row class="d-flex align-center justify-center">
                <v-col cols="3" class="ma-0 pa-4 d-flex flex-column align-center justify-center" v-for="icon in icons" :key="icon.title">
                    <div
                        class="pa-5"
                        :class="[icon.title == active ? 'icon-background-active' : 'icon-background']"
                        @click="active = icon.title">
                        <!-- <v-img v-if="icon.image" :src="icon.image" max-height="40" max-width="40" eager /> -->
                        <v-icon contain size="40">{{ icon.icon }}</v-icon>
                    </div>
                    <div class="icon-text text-center font-weight-bold mt-4" v-html="icon.html"></div>
                </v-col>
            </v-row>
        </div>

        <v-row class="full-width y-5 d-flex align-center justify-center">
            <v-col cols="12" md="8" class="my-3 pa-0 d-flex align-center justify-center">
                <library-form :selectedSubject="active"></library-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import LibraryForm from "@/components/library/library-form.vue";
export default {
    components: {
        LibraryForm,
    },
    data() {
        return {
            active: "Interactive Stories",
        };
    },
    computed: {
        ...mapState(["assets"]),
        icons() {
            return [
                {
                    title: "Interactive Stories",
                    html: "Interactive<br/>Stories",
                    icon: "mdi-book",
                    // image: this.assets.icons.libraryActive,
                },
                {
                    title: "Schedule Creator",
                    html: "Schedule<br/>Creator",
                    icon: "mdi-calendar-month",
                },
                {
                    title: "Custom Solutions",
                    html: "Custom<br/>Solutions",
                    icon: "mdi-bird",
                },
                {
                    title: "Feedback & Ideas",
                    html: "Feedback<br/> & Ideas",
                    icon: "mdi-lightbulb",
                },
                // {
                //   title: "Need Some Help?",
                //   html: "Need Some<br/>Help?",
                //   icon: "mdi-help",
                // },
            ];
        },
    },
    methods: {},
};
</script>

<style scoped lang="scss">
.contact-us-page {
}

.v-text-area .icon-background {
    background-color: #e8e5e5;
    border-radius: 50%;
    flex: 0 0 auto;
}

.icon-background-active {
    background-color: var(--primary);
    border-radius: 50%;
    flex: 0 0 auto;
    * {
        color: #fff !important;
    }
}

::v-deep .v-textarea {
    border: 1px solid rgba(#000, 0.2) !important;
}

.icon-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.width-40vw {
    width: 40vw !important;
}
.width-100vw {
    width: 100vw;
}
</style>
